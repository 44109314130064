import React from "react";
import { image_url } from "action/api";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductCard = ({ data = [] }) => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      paritialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  // Debugging logs to understand the data structure
  console.log("ProductCard data:", data);

  return (
    <div>
      <div className="cursor-pointer grid gap-5 md:gap-8 lg:gap-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-1 px-1 lg:my-4 lg:px-4">
        {Array.isArray(data) && data.length > 0 ? (
          data.map((el, i) => {
            console.log(`Product ${i}:`, el); // Debug log for each product
            return (
              <article
                className="overflow-hidden rounded-lg duration-300"
                key={i}
              >
                {el.Ad_Image &&
                Array.isArray(el.Ad_Image) &&
                el.Ad_Image.length > 0 ? (
                  el.Ad_Image.map((img, imgIndex) => (
                    <Link
                      to={`/${el.id}/${encodeURIComponent(
                        el.name.replace(/\s+/g, "-")
                      )}`}
                      key={imgIndex}
                    >
                      <LazyLoadImage
                        src={image_url + img}
                        alt="Photo Not Available."
                        effect="blur"
                        className="rounded-2xl object-cover h-[200px] md:h-[300px] lg:h-[350px]"
                      />
                    </Link>
                  ))
                ) : (
                  <p>No images available</p>
                )}
                <header className="leading-tight p-1 md:p-2 bg-transparent">
                  <p className="text-gray-700 text-xs">{el.location_name}</p>
                  <h1 className="text-lg">
                    <Link
                      to={`/${el.name}/${el.id}`}
                      className="no-underline text-black text-sm md:text-lg lg:text-lg font-bold hover:text-[#339999] duration-300"
                    >
                      {el.name}
                    </Link>
                  </h1>
                  <strike className="text-xs text-gray-700">
                    ₹{el.oneday_price}
                  </strike>
                  <p className="text-sm text-gray-700">
                    Starting
                    <span className="text-black font-semibold ml-1">
                      ₹{el.actual_price_discount}/-
                    </span>
                  </p>
                </header>
              </article>
            );
          })
        ) : (
          <p>No products available</p>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
