import SectionGrid from "components/SectionGrid/SectionGrid";
import SectionTitle from "components/SectionTitle/SectionTitle";
import Container from "components/UI/Container/Container";
import { PostPlaceholder } from "components/UI/ContentLoader/ContentLoader";
import Heading from "components/UI/Heading/Heading";
import SinglePageWrapper, {
  PostImage,
} from "containers/SinglePage/SinglePageView.style";
import React, { useEffect, useState } from "react";
import { SINGLE_POST_PAGE } from "settings/constant";
import NoBudgetFound from "./NoBudgetFound";
import { useParams, useSearchParams } from "react-router-dom";
import useLocationApi from "library/hooks/useLoactionApi";
import useWindowSize from "library/hooks/useWindowSize";
import useBudgetAPI from "library/hooks/useBudgetAPI";
import ProductCard from "components/Card/ProductCard";
import { getBudget, getBudgetDetails, getVillaByBudget, image_url } from "action/api";
import { Helmet } from "react-helmet";

const BudgetInGrid = () => {
  const [searchParam] = useSearchParams();
  const { loading } = useBudgetAPI("/data/top-hotel.json");
  const { width } = useWindowSize();

  let posts = data;
  let limit;

  if (data && width <= 767) {
    posts = data.slice(0, 6);
    limit = 6;
  }
  if (data && width >= 768) {
    posts = data.slice(0, 10);
    limit = 10;
  }
  if (data && width >= 992) {
    posts = data.slice(0, 14);
    limit = 14;
  }
  if (data && width >= 1200) {
    posts = data.slice(0, 20);
    limit = 20;
  }

  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const { id } = useParams();


  const getAllBudget = async () => {
    let result = await getVillaByBudget(id)
    // console.log("Budget By vill",result.data);
    setData(result.data);
  };
  const getBudget = async () => {
    let actiom = await getBudgetDetails(id)
    setName(actiom.data.price)
    setImage(actiom.data.image)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBudget();
    getBudget()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBudget();
    getBudget()
  }, [id]);
  return (
    <div>
      <Helmet>
        <title>Jalanta Stays - Budget {name}</title>
      </Helmet>

      <SinglePageWrapper>
        <PostImage>
          <img
            className="absolute"
            src={image_url + image}
            alt="Listing details page banner"
          />
        </PostImage>
        <div className="bg-white border-b">
          <div className="ml-5">
            <SectionTitle
              title={<Heading content={name} />}
            />
          </div>
        </div>
        <Container fluid={true} className="mt-10">
          <ProductCard link={SINGLE_POST_PAGE} data={data} />
        </Container>
      </SinglePageWrapper>
    </div>
  );
};

export default BudgetInGrid;
