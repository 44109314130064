import React, { useEffect } from "react";
import PageHeader from "../Pages/PageHeader";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
    <Helmet>
    <title>Jalanta Stays - Privacy Policy</title>
  </Helmet>
    <div className="bg-slate-100">
      <PageHeader
        title="Privacy Policy"
        banner="url('https://imgtr.ee/images/2023/04/28/Jnt5i.jpg')"
      />
      <div className="bg-slate-100">
        <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10">
          <div className="flex flex-col gap-10 md:px-28 lg:px-40 px-2">
            <div className="bg-white w-100 rounded-lg h-40 flex flex-col justify-end shadow-lg">
              <div className="p-5">
                <p className="text-[#008489] font-bold text-3xl">
                  Policies & Guidelines
                </p>
              </div>
            </div>
            <div className="bg-white rounded-lg justify-end shadow-lg">
              <div className="p-5 py-5 lg:py-20 md:py-20 flex flex-col gap-10 px-5 md:px-10 lg:px-20">
                <p className="text-[#008489] text-2xl md:text-3xl lg:text-3xl">
                PRIVACY POLICY
                </p>

                {/* 1st Paragraph */}
                <div className="flex flex-col gap-3">
                  {/* <p className="font-bold text-2xl md:text-3xl lg:text-3xl" >
                    Check-In and Check-Out:
                  </p> */}
                  <div className="ml-5">
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                     As a Jalanta Stays user, you’ll be giving sensitive information like your phone details, address, bank account numbers and more. Jalanta Stays takes privacy very seriously and implements strong controls to protect your personal information. The Privacy Policy document states Jalanta Stays’ policies and procedures on gathering and using your personal information. Read further on how Jalanta Stays is keeping your personal information safe.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Jalanta Stays Private Limited ("JalantaStays", "we" or "us") provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of personal information we receive from users of www.JalantaStays.com and www.JalantaStudio.in (collectively, the "Site") and our application for mobile devices (the "Application"). This Privacy Policy applies only to information that you provide to us through the Site and Application. This Privacy Policy may be updated from time to time to reflect changes to our information practices. If we make any material changes we will notify you by means of a notice on the Site and Application prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Service.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      As used in this policy, the terms "using" and "processing" information include using cookies on a computer, subjecting the information to statistical or other analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization or among our affiliates/ service providers within India or internationally.
                    </li>
                  </div>
                </div>

                {/* 2nd Paragraph */}
                <div className="flex flex-col gap-3">
                  <p className="font-bold text-2xl md:text-3xl lg:text-3xl"  >
                  Refund for Extra Charges
                  </p>
                  <div className="ml-5">
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      In case of any change in group size, the extra bedding charges collected can be refunded if intimated to Jalanta Stays minimum 2 days prior to check-in. As with all refunds a 5% processing fee shall be levied for processing the refund. No refund for extra bedding charges shall be processed if informed within 2 days prior check-in or after check-in.
                    </li>
                    {/* <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Guests are not allowed to access the kitchen except for
                      infants with prior permission.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Guests cannot invite other guests and day visitors during
                      their stay.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Bachelor groups & stag parties are not allowed.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      We do not encourage parties and late-night revelry. Loud
                      music is not permitted beyond 10 pm.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Consumption of alcohol is permitted only within limits.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Illegal activities including but not limited to
                      carrying/consuming drugs/narcotics and carrying
                      firearms/weapons are prohibited on the property.
                      Management reserves the right to report any such
                      activities to local authorities.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Smoking within the villa/rooms is prohibited.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      The pool (if present) is unguarded. There is no lifeguard
                      on site. Children in the pool must be accompanied by
                      adults at all times.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Guests are requested to take care of all personal
                      valuables. Management is not responsible for loss, theft
                      or damage to any items.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      As required by law, every guest above 18 years must carry
                      government-approved photo ID proof, that shows address. We
                      accept only Aadhar Cards, Driving Licenses and Passports.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Inappropriate behavior or violation of any of the above
                      House Rules will invite a polite refusal to accept a
                      Booking. If already checked in, Management reserves the
                      right to ask guests to leave.
                    </li> */}
                  </div>
                </div>

                {/* 3rd Paragraph */}
                <div className="flex flex-col gap-3">
                  <p className="font-bold text-2xl md:text-3xl lg:text-3xl" >
                  Cancellation by Jalanta Stays
                  </p>
                  <div className="ml-5">
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Jalanta Stays reserves to right to cancel a booking. Jalanta Stays will offer the guest one of the following options.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Book an alternative comparable available Home, if available
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Jalanta Stays shall offer Travel Credit (validity of 6 months from date of issuance) of the full amount paid + 10% value of the existing booking that may be redeemed while booking a new home.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      A full refund for the total amount paid. The refund shall be processed within 5-7 working days from the date of cancellation.
                    </li>
                    {/* <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      Both veg and non-veg barbeque will only be served on prior
                      demand and at an additional charge of Rs. 1,000 and
                      suffices 5-6 people.
                    </li>
                    <li
                      className="list-disc text-xl md:text-2xl lg:text-2xl"

                    >
                      There is one additional room on the Ground Floor for
                      senior citizens. Please inform about the same during your
                      booking.
                    </li> */}
                  </div>
                </div>

                {/* Styled Border */}
                <div className="border-2 border-[#008489] w-10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;
