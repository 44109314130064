import React from "react";
import { FiExternalLink } from "react-icons/fi";
import TextLink from "components/UI/TextLink/TextLink";
import Rating from "components/UI/Rating/Rating";
import Favourite from "components/UI/Favorite/Favorite";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GridCard from "../GridCard/GridCard";
import { useNavigate } from "react-router-dom";
import { image_url } from "action/api";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    paritialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const PostGrid = ({
  name,
  rating,
  location_name,
  oneday_price,
  ratingCount,
  Ad_Image,
  id,
  link,
  days_price,
}) => {
  let Navigate = useNavigate();

  return (
    <>
      {days_price &&
        days_price.map((el, i) => {
          return (
            <div key={i}>
              <GridCard
                // onClick={() => Navigate(`${link}/${id}`)}
                isCarousel={true}
                favorite={
                  <Favourite
                    onClick={(event) => {
                      // console.log(event);
                    }}
                  />
                }
                id={id}
                location={location_name ? location_name : "Location"}
                title={<TextLink link={`${link}/${id}`} content={name} />}
                actualPrice={days_price ? el.actual_price : null}
                price={`₹${days_price ? el.standard_price : oneday_price}/${
                  days_price ? el.days : "Day"
                } - Free Cancellation`}
                // rating={<Rating rating={4} ratingCount={5} type="bulk" />}
                // viewDetailsBtn={
                //   <TextLink
                //     link={`${link}/${id}`}
                //     icon={<FiExternalLink />}
                //     content="View Details"
                //   />
                // }
              >
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  renderDotsOutside={false}
                  responsive={responsive}
                  showDots={true}
                  sliderClass=""
                  slidesToSlide={1}
                >
                  {Ad_Image &&
                    Ad_Image.map((url, index) => (
                      <img
                        src={
                          image_url + url
                        }
                        alt="Photo Not Available."
                        key={index}
                        draggable={false}
                        style={{
                          width: "100%",
                          height: "110%",
                          objectFit: "cover",
                          // position: "relative",
                        }}
                      />
                    ))}
                </Carousel>
              </GridCard>
            </div>
          );
        })}
    </>
  );
};

export default PostGrid;
