import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  PRICING_PLAN_PAGE,
  ABOUT_PAGE,
  CONTACT_PAGE,
} from 'settings/constant';

const menuItems = [
  {
    label: <NavLink to={HOME_PAGE}>Home</NavLink>,
    key: 'menu-1',
  },
  {
    label: <NavLink to={ABOUT_PAGE}>About Us</NavLink>,
    key: 'menu-2',
  },
  {
    label: <NavLink to={CONTACT_PAGE}>Contact Us</NavLink>,
    key: 'menu-3',
  },
  {
    label: <NavLink to={PRICING_PLAN_PAGE}>Order History</NavLink>,
    key: 'menu-4',
  },
];

const MainMenu = ({ className }) => {
  return <Menu className={className} items={menuItems} />;
};

export default MainMenu;
