import React, { useEffect, useState } from "react";
import NoBHKFound from "./NoBHKFound";
import SinglePageWrapper, {
  PostImage,
} from "containers/SinglePage/SinglePageView.style";
import SectionTitle from "components/SectionTitle/SectionTitle";
import Heading from "components/UI/Heading/Heading";
import Container from "components/UI/Container/Container";
import SectionGrid from "components/SectionGrid/SectionGrid";
import { SINGLE_POST_PAGE } from "settings/constant";
import { PostPlaceholder } from "components/UI/ContentLoader/ContentLoader";
import { useParams, useSearchParams } from "react-router-dom";
import useWindowSize from "library/hooks/useWindowSize";
import useBHKapi from "library/hooks/useBHKapi";
import ProductCard from "components/Card/ProductCard";
import { getBhk, getBhkDetails, getVillsByBhkid, image_url } from "action/api";
import { Helmet } from "react-helmet";

const BHKInGrid = () => {
  const [searchParam] = useSearchParams();
  const { loading } = useBHKapi("/data/top-hotel.json");
  const { width } = useWindowSize();

  let posts = data;
  let limit;

  if (data && width <= 767) {
    posts = data.slice(0, 6);
    limit = 6;
  }
  if (data && width >= 768) {
    posts = data.slice(0, 10);
    limit = 10;
  }
  if (data && width >= 992) {
    posts = data.slice(0, 14);
    limit = 14;
  }
  if (data && width >= 1200) {
    posts = data.slice(0, 20);
    limit = 20;
  }

  const [data, setData] = useState([]);
  const [name, setName] = useState("")
  const [image, setImage] = useState("")
  const { id } = useParams();

  const getAllBHK = async () => {
    let result = await getVillsByBhkid(id)
    // console.log("Villa by Bhk", result);
    setData(result.data);

  };

  const getBhk = async () => {
    let result = await getBhkDetails(id)
    setName(result.data.bhk_room)
    setImage(result.data.image)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBHK();
    getBhk()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllBHK();
    getBhk()
  }, [id]);



  return (
    <div>
      <Helmet>
        <title>Jalanta Stays - {name}</title>
      </Helmet>

      <SinglePageWrapper>
        <PostImage>
          <img
            className="absolute"
            src={
              image_url +
              image
            }
            alt="Listing details page banner"
          />
        </PostImage>
        <div className="bg-white border-b">
          <div className="ml-5">
            <SectionTitle
              title={<Heading content={name} />}
            />
          </div>
        </div>
        {data.length === 0 ? (
          <NoBHKFound />
        ) : (
          <Container fluid={true} className="mt-10">
            <ProductCard link={SINGLE_POST_PAGE} data={data} />
          </Container>
        )}
      </SinglePageWrapper>
    </div>
  );
};

export default BHKInGrid;
