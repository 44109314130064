import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BsFillDiscFill } from "react-icons/bs";
import { useParams, useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { getBookingDetails, image_url } from "action/api";

//D:\react JS\jalanta web\src\components\Success\Success.js
const Success = () => {
  const [searchParam] = useSearchParams();
  let { slug } = useParams();
  let visitorData = searchParam.get("VisitorData");
  const [data, setData] = useState("")

  const getBooking = async()=>{
    let action = await getBookingDetails(slug)
    if(action.status == 200){
      setData(action.data)
    }
  }
  // const momentObj = moment(data.start_date, "MM/DD/YYYY");
  // const formattedStartDate = momentObj.format("DD MMM YYYY");

  // const momentObj2 = moment(data.start_date, "MM/DD/YYYY");
  // const formattedEndDate = momentObj2.format("DD MMM YYYY");

  useEffect(() => {
    window.scroll(0, 0);
    getBooking()
  }, []);

  const pdfRef = useRef(null);

  const generatePdf = () => {
    const htmlMarkup = pdfRef.current.outerHTML;

    // Create the PDF using html2pdf
    html2pdf().from(htmlMarkup).save("Jalanta-Stays-Booking.pdf");
  };

  let url = searchParam.get("base_image");

  return (
    <div
      className="bg-slate-50 p-2 md:p-8 lg:p-10 animate-bounce duration-300"
      style={{ animationIterationCount: 1 }}
    >
      <div className="py-5 flex justify-center">
        <button
          onClick={generatePdf}
          className="p-2 rounded bg-js text-white font-semibold"
        >
          Download PDF
        </button>
      </div>

      <div ref={pdfRef} className="bg-white rounded-3xl">
        <div className="p-5 shadow-lg">
          <div>
            <img
              src={image_url + data.villa_img}
              alt="Booked Villa Image"
              className="h-80 w-full object-cover shadow-2xl rounded-3xl"
            />
          </div>
          <h1 className="text-js text-center text-4xl font-[900] font-serif py-10">
            Booking Successfull
          </h1>
          <hr className="border border-js mx-2 md:mx-10 lg:mx-20" />
          <div className="py-10 mx-2 md:mx-10 lg:mx-20">
            <h1 className="flex gap-5 items-center">
              <BsFillDiscFill className="text-js text-2xl" />
              <span className="text-js text-2xl font-[800] font-serif">
                Visitor Details :
              </span>
            </h1>
            <div className="ml-10 py-5 flex flex-col gap-3">
              <p className="text-gray-700 font-bold">
                Name :{" "}
                <span className="text-black font-medium">
                  {data.booking_name}
                </span>
              </p>
              <p className="text-gray-700 font-bold">
                Email :{" "}
                <span className="text-black font-medium">{data.email_id}</span>
              </p>
              <p className="text-gray-700 font-bold">
                Mobile No :{" "}
                <span className="text-black font-medium">{data.mobile_no}</span>
              </p>
            </div>
          </div>

          <div className="mx-2 md:mx-10 lg:mx-20">
            <h1 className="flex gap-5 items-center">
              <BsFillDiscFill className="text-js text-2xl" />
              <span className="text-js text-2xl font-[800] font-serif">
                Villa Details :
              </span>
            </h1>
          </div>

          <div className="relative overflow-x-auto mx-2 md:mx-10 lg:mx-20 py-5">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs rounded text-slate-100 uppercase bg-js ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Stay
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Paid
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pending
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total Payment
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-black"
                  >
                    {data.villa_name}
                  </th>
                  <td className="px-6 py-4">
                    {data.start_date}
                    <p classname>to</p> <p>{data.end_date}</p>
                  </td>
                  <td className="px-6 py-4">₹{data.payment_paid}/-</td>
                  <td className="px-6 py-4">₹{data.pending_payment}/-</td>
                  <td className="px-6 py-4">₹{data.total_payment}/-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
