import moment from "moment";
import imageCompression from 'browser-image-compression';
import axios from 'axios';


const base_url = "https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api";
const image_url = "https://jalantastays.com/rest/api/X8K9Pz0/backend/public/";
const logo_path = "https://jalantastays.com/rest/api/X8K9Pz0/backend/public/files/villa/base_image/1685078835_logo with tagline_1.png"
export { base_url, image_url, logo_path }


export const getLocations = async () => {
  const apiUrl = `${base_url}/getEnableLocation`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    return { status: 0, data: [] };
  }
};

export const getBhk = async () => {

  const apiUrl = `${base_url}/getEnableBHK`;
  try {
    const response = await axios(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching BHKs:', error);
    return { status: 0, data: [] };
  }
};

export const getBudget = async () => {
  const apiUrl = `${base_url}/getEnableBudgetRange`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching budget ranges:', error);
    return { status: 0, data: [] };
  }
};

export const getVillaMostBooked = async () => {
  const apiUrl = `${base_url}/enableAndMostBooked`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching most booked villas:', error);
    return { status: 0, data: [] };
  }
};

export const getVillsByBhkid = async (id) => {
  const apiUrl = `${base_url}/getVillaByBHKID/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching villas by BHK ID:', error);
    return { status: 0, data: [] };
  }
};


export const getVillaByBudget = async (id) => {
  const apiUrl = `${base_url}/getByBudgeID/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching villas by budget:', error);
    return { status: 0, data: [] };
  }
};

export const getVillaBookedDates = async (id) => {
  const apiUrl = `${base_url}/getUnAvailableVilla/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching booked villa dates:', error);
    return { status: 0, data: [] };
  }
};



export const getLocatiionDetails = async (id) => {
  const apiUrl = `${base_url}/location/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching location details:', error);
    return { status: 0, data: [] };
  }
};

export const getBudgetDetails = async (id) => {
  const apiUrl = `${base_url}/budget/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching budget details:', error);
    return { status: 0, data: [] };
  }
};

export const getBhkDetails = async (id) => {
  const apiUrl = `${base_url}/bhk_route/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching BHK details:', error);
    return { status: 0, data: [] };
  }
};

export const getBookingDetails = async (id) => {
  const apiUrl = `${base_url}/getEncrypt/${id}`; // Make sure base_url is defined somewhere

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching booking details:', error);
    return { status: 0, data: [] };
  }
};

export const getPaymentDetails = async (id, start_date, end_date) => {
  var resData;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "startDate": moment(start_date).format('YYYY-MM-DD').toString(),
    "endDate": moment(end_date).format('YYYY-MM-DD').toString(),
  })
  // console.log("API RAW"+end_date)
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  await fetch(`${base_url}/guestExtraLimit/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      resData = result;
    })
    .catch((error) => {
      // console.log("error", error)
      resData = { status: 0, error: error }
    });
  return resData;
}

export const sendBookingHistory = async email_id => {
  var resData;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "email_id": email_id
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`${base_url}/bookingHistory`, requestOptions)
    .then(response => response.json())
    .then(result => {
      // console.log(result)
      resData = result;
    })
    .catch(error => {
      // console.log('error', error)
      resData = { status: 0, error: error, data: [] }
    });
  return resData;
}

export const addVistory = async (name, contact, budget) => {
  var resData;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "name": name,
    "contact": contact,
    "budget": budget
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(`${base_url}/PopupEnquiriesController`, requestOptions)
    .then(response => response.json())
    .then(result => {
      // console.log(result)
      resData = result;
    })
    .catch(error => {
      // console.log('error', error)
      resData = { status: 0, error: error, data: [] }
    });
  return resData;
}

export const compressImage = async (image) => {
  var resData;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920
  }
  try {
    const compressedFile = await imageCompression(image, options);
    // console.log("file size",compressedFile.size/1024/1024);
    resData = compressedFile
  } catch (error) {
    // console.log(error);
    resData = image
  }
  return resData
}