import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { FaMapMarkerAlt, FaWarehouse } from "react-icons/fa";
import { getBhk } from "action/api";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BHK_DETAILS_PAGE } from "settings/constant";

const BHKDropdown = ({sidebarHandler}) => {
  const [data, setData] = useState([]);
  const Navigate = useNavigate()
  const getAllBHK = async () => {
        let result = await getBhk()
        setData(result.data);
   
  };

  useEffect(() => {
    getAllBHK();
  }, []);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const bhkItems = data.map((bhk) =>
    getItem(bhk.bhk_room, bhk.id, null, null, "bhk")
  );

  const items = [
    getItem(<p className="font-bold">BHK's</p>, "sub1", <FaWarehouse />, [
      ...bhkItems,
    ]),
  ];
  const onClick = (e) => {
    // console.log("click ", e);
    Navigate({
      pathname: BHK_DETAILS_PAGE,
      search: createSearchParams({
        id: e.key,
        // name: el.bhk_room,
        // image: el.image,
      }).toString(),
    })
    sidebarHandler()
  };

  return (
    <div>
      <Menu
        onClick={onClick}
        // defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default BHKDropdown;
