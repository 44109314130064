import React, { useState } from "react";
import { Select, Space } from "antd";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useEffect } from "react";
import axios from 'axios';

const SearchInput = ({ getInputValue }) => {
  const [data, setData] = useState([]);


  const getAllLocations = async () => {
    try {
      const response = await axios.get('https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/getAllLocation');
      const mappedOptions = response.data.data.map((option) => ({
        value: option.id,
        label: option.location_name,
      }));
      setData(mappedOptions);
    } catch (error) {
      console.error('Error fetching all locations:', error);
    }
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const onChange = (value) => {
    // console.log(`selected ${value}`);
    getInputValue(value);
  };

  return (
    <div className="map_autocomplete ml-1 w-full">
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Select
          dropdownStyle={{
            width: "100%",
            zIndex: 9999,
          }}
          size="large"
          className="w-full rounded-md border-gray-300 shadow-sm bg-white hover:border-[#008489]"
          showArrow={false}
          placeholder="Select a Location"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.location_name ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={data}
        />
      </Space>
    </div>
  );
};

const MapAutoComplete = (props) => {
  const { updateValue } = props;
  return <SearchInput getInputValue={updateValue} />;
};

export default MapAutoComplete;
