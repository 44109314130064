import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../Pages/PageHeader";
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import InputArea from "./InputArea";
import Label from "./Label";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Message, setMessage] = useState("");

  // Error Hooks
  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [contactError, setcontactError] = useState("");
  const [messageError, setmessageError] = useState("");

  // Loader
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    setnameError("");
    setemailError("");
    setcontactError("");
    setmessageError("");
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "") {
      setnameError("please enter your name");
    } else if (Email === "") {
      setemailError("please enter your email");
    } else if (!emailRegex.test(Email)) {
      setemailError("Please enter a valid email");
    } else if (Contact === "") {
      setcontactError("please enter your contact");
    } else if (!phoneRegex.test(Contact)) {
      setcontactError("Please enter a valid phone number");
    } else if (Message === "") {
      setmessageError("please enter your message");
    } else {
      setIsLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: name,
        email: Email,
        contact_no: Contact,
        message: Message,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/contact_us",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.status === 200) {
            toast.success("Message sent successfully", {
              theme: "light",
              autoClose: "2000",
            });
            setIsLoading(false);
            setname("");
            setEmail("");
            setContact("");
            setMessage("");
          }
        })
      // .catch((error) => console.log("error", error));
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#fff",
      }}
      spin
    />
  );

  return (
    <div>
      <Helmet>
        <title>Jalanta Stays - Contact Us</title>
      </Helmet>
      <PageHeader
        title="Contact Us"
        banner="url('https://imgtr.ee/images/2023/04/28/Jnt5i.jpg')"
      />
      <div className="bg-white">
        <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10">
          {/* contact promo */}
          <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8 font-serif">
            <div className="border p-10 rounded-lg text-center">
              <span className="flex justify-center text-4xl text-[#12406d] mb-4">
                <FiMail />
              </span>
              <h5 className="text-xl mb-2 font-bold">Email Us</h5>
              <p className=" text-base opacity-90 leading-7">
                <Link to="/" className="text-[#12406d]">
                  Jalantastays@gmail.com
                </Link>{" "}
                Enteractively grow empowered for process-centric total linkage.
              </p>
            </div>
            <div className="border p-10 rounded-lg text-center">
              <span className="flex justify-center text-4xl text-[#12406d] mb-4">
                <FiPhoneCall />
              </span>
              <h5 className="text-xl mb-2 font-bold">Call Us</h5>
              <p className=" text-base opacity-90 leading-7">
                <Link to="/" className="text-[#12406d]">
                  +91 8421133387  +91 9049933387
                </Link>{" "}
                Distinctively disseminate focused solutions clicks-and-mortar
                ministate.
              </p>
            </div>
            <div className="border p-10 rounded-lg text-center">
              <span className="flex justify-center text-4xl text-[#12406d] mb-4">
                <FiMapPin />
              </span>
              <h5 className="text-xl mb-2 font-bold">Location</h5>
              <p className=" text-base opacity-90 leading-7">
                <Link to="/" className="text-[#12406d]"></Link>Pune , Maharashtra
              </p>
            </div>
          </div>

          {/* contact form */}
          <div className="px-0 pt-24 mx-auto items-center flex flex-col md:flex-row w-full justify-between">
            <div className="hidden md:w-full lg:w-5/12 lg:flex flex-col h-full">
              <img
                width={874}
                height={874}
                src="https://jalantastays.com/rest/api/X8K9Pz0/backend/public/files/villa/base_image/1685078835_logo with tagline_1.png"
                alt="logo"
                className="block w-auto"
              />
            </div>
            <div className="px-0 pb-2 lg:w-5/12 flex flex-col md:flex-row">
              <form
                onSubmit={() => { }}
                className="w-full mx-auto flex flex-col justify-center"
              >
                <div className="mb-12">
                  <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold font-serif mb-3">
                    For any support just send your query
                  </h3>
                  <p className="text-base opacity-90 leading-7">
                    Collaboratively promote client-focused convergence vis-a-vis
                    customer directed alignments via plagiarize strategic users
                    and standardized infrastructures.
                  </p>
                </div>

                <div className="flex flex-col space-y-5">
                  <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
                    <div className="w-full md:w-1/2 ">
                      <InputArea
                        label="Your Name"
                        name="name"
                        type="text"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                      {nameError && (
                        <div
                          className="text-red-500 text-xs font-semibold my-1 ml-1 animate-bounce"
                          style={{ animationIterationCount: 1 }}
                        >
                          {nameError}
                        </div>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 md:ml-2.5 lg:ml-5 mt-2 md:mt-0">
                      <InputArea
                        label="Your Email"
                        name="email"
                        type="email"
                        placeholder="Enter Your Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && (
                        <div className="text-red-500 text-xs font-semibold my-1 ml-1">
                          {emailError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative">
                    <InputArea
                      label="Mobile Number"
                      name="mobileNumber"
                      type="text"
                      placeholder="Enter Your Mobile Number"
                      value={Contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                    {contactError && (
                      <div className="text-red-500 text-xs font-semibold my-1 ml-1">
                        {contactError}
                      </div>
                    )}
                    <div className="relative mb-4 mt-3">
                      <Label label="Message" />
                      <textarea
                        name="message"
                        className="px-4 py-3 flex items-center w-full rounded appearance-none opacity-75 transition duration-300 ease-in-out text-sm focus:ring-0 bg-white border border-gray-300 focus:shadow-none focus:outline-none focus:border-gray-500 placeholder-body"
                        autoComplete="off"
                        spellCheck="false"
                        rows="4"
                        placeholder="Write your message here"
                        value={Message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      {messageError && (
                        <div className="text-red-500 text-xs font-semibold my-1 ml-1">
                          {messageError}
                        </div>
                      )}
                    </div>
                    <div className="relative">
                      <button
                        data-variant="flat"
                        className={`md:text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none bg-js text-white px-5 md:px-6 lg:px-8 py-3 md:py-3.5 lg:py-3 hover:text-white hover:#399999 h-12 mt-1 text-sm lg:text-base w-full sm:w-auto`}
                        onClick={handleSendMessage}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
