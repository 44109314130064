import React, { useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "context/AuthProvider";
import Layout from "containers/Layout/Layout";
import Loader from "components/Loader/Loader";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_IMAGE_EDIT_PAGE,
  AGENT_PASSWORD_CHANGE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  LOCATION_DETAILS_PAGE,
  NO_VILLA_FOUND,
  NO_BHK_FOUND,
  BHK_DETAILS_PAGE,
  NO_BUDGET_FOUND,
  BUDGET_DETAILS_PAGE,
  ABOUT_PAGE,
  CONTACT_PAGE,
  PRIVACY_POLICY_PAGE,
  SUCCESS_PAGE,
  CHECK_OUT_PAGE,
  FAIL_PAGE,
  VIEW_ALL_VILLA,
} from "./settings/constant";
import LocationInGrid from "components/LocationHome/LocationInGrid";
import NoVillaFound from "components/LocationHome/NoVillaFound";
import BHKInGrid from "components/BHK/BHKInGrid";
import NoBHKFound from "components/BHK/NoBHKFound";
import BudgetInGrid from "components/Budget/BudgetInGrid";
import NoBudgetFound from "components/Budget/NoBudgetFound";
import AboutUs from "containers/Home/Pages/AboutUs";
import ContactUs from "containers/Home/Pages/ContactUs";
import PrivacyPolicy from "containers/Home/Pages/PrivacyPolicy";
import Success from "components/Success/Success";
import CheckOutPage from "containers/Home/Pages/CheckOutPage";
import Fail from "components/Success/Fail";


// protected route
function RequireAuth({ children }) {
  let { loggedIn } = useContext(AuthContext);
  let location = useLocation();
  if (!loggedIn) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
  }

  return children;
}

// public routes
const HomePage = React.lazy(() => import("containers/Home/Home"));
const ListingPage = React.lazy(() => import("containers/Listing/Listing"));
const ViewAll = React.lazy(() => import("containers/viewall/ViewAll"));
const SinglePageView = React.lazy(() =>
  import("containers/SinglePage/SinglePageView")
);
const AgentDetailsViewPage = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentDetailsViewPage")
);
const AgentItemLists = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentItemLists")
);
const AgentFavItemLists = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentFavItemLists")
);
const AgentContact = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentContact")
);
const PricingPage = React.lazy(() => import("containers/Pricing/Pricing"));
const PrivacyPage = React.lazy(() => import("containers/Privacy/Privacy"));
const SignInPage = React.lazy(() => import("containers/Auth/SignIn/SignIn"));
const SignUpPage = React.lazy(() => import("containers/Auth/SignUp/SignUp"));
const ForgetPasswordPage = React.lazy(() =>
  import("containers/Auth/ForgetPassword")
);
const NotFound = React.lazy(() => import("containers/404/404"));
// protected route
const AddListingPage = React.lazy(() =>
  import("containers/AddListing/AddListing")
);
const AgentAccountSettingsPage = React.lazy(() =>
  import("containers/Agent/AccountSettings/AgentAccountSettingsPage")
);
const AgentCreateOrUpdateForm = React.lazy(() =>
  import("containers/Agent/AccountSettings/AgentCreateOrUpdateForm")
);
const AgentPictureChangeForm = React.lazy(() =>
  import("containers/Agent/AccountSettings/AgentPictureChangeForm")
);
const ChangePassWord = React.lazy(() =>
  import("containers/Agent/AccountSettings/ChangePassWordForm")
);

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path={HOME_PAGE} element={<Layout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<Loader />}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path={ABOUT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AboutUs />
              </React.Suspense>
            }
          />
          <Route
            path={CHECK_OUT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <CheckOutPage />
              </React.Suspense>
            }
          />
          <Route
            path={CONTACT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ContactUs />
              </React.Suspense>
            }
          />
          <Route
            path={PRIVACY_POLICY_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />
          <Route
            path={`${SUCCESS_PAGE}/:slug`}
            element={
              <React.Suspense fallback={<Loader />}>
                <Success />
              </React.Suspense>
            }
          />
          <Route
            path={`${FAIL_PAGE}`}
            element={
              <React.Suspense fallback={<Loader />}>
                <Fail />
              </React.Suspense>
            }
          />
          <Route
            path={LISTING_POSTS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ListingPage />
              </React.Suspense>
            }
          />
          <Route
            path={VIEW_ALL_VILLA}
            element={
              <React.Suspense fallback={<Loader />}>
                <ViewAll />
              </React.Suspense>
            }
          />
          <Route
            path="/:id/:villaName"
            element={
              <React.Suspense fallback={<Loader />}>
                <SinglePageView />
              </React.Suspense>
            }
          />
          {/* Location Routing */}
          <Route
            path="/:id/Location/:locationName"
            element={
              <React.Suspense fallback={<Loader />}>
                <LocationInGrid />
              </React.Suspense>
            }
          />
          <Route
            path={NO_VILLA_FOUND}
            element={
              <React.Suspense fallback={<Loader />}>
                <NoVillaFound />
              </React.Suspense>
            }
          />
          {/* BHK Routing */}
          <Route
            path="/:id/BHK/:BHKName"
            element={
              <React.Suspense fallback={<Loader />}>
                <BHKInGrid />
              </React.Suspense>
            }
          />
          <Route
            path={NO_BHK_FOUND}
            element={
              <React.Suspense fallback={<Loader />}>
                <NoBHKFound />
              </React.Suspense>
            }
          />

          {/* Budget Routing */}
          <Route
            path="/:id/Budget/:BudgetName"
            element={
              <React.Suspense fallback={<Loader />}>
                <BudgetInGrid />
              </React.Suspense>
            }
          />
          <Route
            path={NO_BUDGET_FOUND}
            element={
              <React.Suspense fallback={<Loader />}>
                <NoBudgetFound />
              </React.Suspense>
            }
          />

          {/* Nested routes for agent page */}
          <Route
            path={AGENT_PROFILE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentDetailsViewPage />
              </React.Suspense>
            }
          >
            <Route
              path={AGENT_PROFILE_PAGE}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AgentItemLists />
                </React.Suspense>
              }
            />
            <Route
              path={AGENT_PROFILE_FAVORITE}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AgentFavItemLists />
                </React.Suspense>
              }
            />
            <Route
              path={AGENT_PROFILE_CONTACT}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AgentContact />
                </React.Suspense>
              }
            />
          </Route>
          {/* end of Nested routes */}
          <Route
            path={PRICING_PLAN_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <PricingPage />
              </React.Suspense>
            }
          />
          <Route
            path={PRIVACY_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <PrivacyPage />
              </React.Suspense>
            }
          />
          <Route
            path={LOGIN_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <SignInPage />
              </React.Suspense>
            }
          />
          <Route
            path={REGISTRATION_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <SignUpPage />
              </React.Suspense>
            }
          />
          <Route
            path={FORGET_PASSWORD_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ForgetPasswordPage />
              </React.Suspense>
            }
          />
          {/* Protected routes */}
          <Route
            path={ADD_HOTEL_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AddListingPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_ACCOUNT_SETTINGS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AgentAccountSettingsPage />
                </RequireAuth>
              </React.Suspense>
            }
          >
            <Route
              path={AGENT_ACCOUNT_SETTINGS_PAGE}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AgentCreateOrUpdateForm />
                </React.Suspense>
              }
            />
            <Route
              path={AGENT_IMAGE_EDIT_PAGE}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AgentPictureChangeForm />
                </React.Suspense>
              }
            />
            <Route
              path={AGENT_PASSWORD_CHANGE_PAGE}
              element={
                <React.Suspense fallback={<Loader />}>
                  <ChangePassWord />
                </React.Suspense>
              }
            />
          </Route>
          {/* end of Protected routes*/}
          <Route
            path="*"
            element={
              <React.Suspense fallback={<Loader />}>
                <NotFound />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
