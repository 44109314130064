// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Pages
export const ABOUT_PAGE = "/About-Us";
export const CONTACT_PAGE = "/Contact-Us";
export const PRIVACY_POLICY_PAGE = "/Privacy-Policy";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/Listing";
export const VIEW_ALL_VILLA = "/AllVilla";
export const SINGLE_POST_PAGE = "/VillaName/id";

// Location Pages
export const LOCATION_DETAILS_PAGE = "Location";
export const NO_VILLA_FOUND = "/Loaction-Not-Found";

// BHK Pages
export const BHK_DETAILS_PAGE = "BHK";
export const NO_BHK_FOUND = "/BHK-Not-Found";

// Budget Pages
export const BUDGET_DETAILS_PAGE = "Budget";
export const NO_BUDGET_FOUND = "/Budget-Not-Found";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/Profile";
export const AGENT_PROFILE_FAVORITE = "Favorite-post";
export const AGENT_PROFILE_CONTACT = "Contact";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "edit";
export const AGENT_IMAGE_EDIT_PAGE = "change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "change-password";
export const AGENT_PROFILE_DELETE = "/delete";

// Other Pages
export const PRICING_PLAN_PAGE = "/Order-History";
export const PRIVACY_PAGE = "/Privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";

// Success Page
export const CHECK_OUT_PAGE ="/check_out-Jalanat-Stays";
export const SUCCESS_PAGE = "/booking-successfull";
export const FAIL_PAGE = "/booking-failed";


