import React from "react";
import PageHeader from "./PageHeader";
import { logo_path } from "action/api";
import { NavLink } from "react-router-dom";
import { CHECK_OUT_PAGE } from "settings/constant";
import { Helmet } from "react-helmet";


const AboutUs = () => {
  return (
    <div>
      <>
        <Helmet>
          <title>Jalanta Stays - About Us</title>
        </Helmet>
        <PageHeader title="About Us" banner="url('https://imgtr.ee/images/2023/04/28/Jnt5i.jpg" />
        <div className="bg-white">
          <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10">
            <div className="grid grid-flow-row lg:grid-cols-2 gap-4 lg:gap-16 items-center">
              <div className="">
                <h3 className="text-xl lg:text-3xl mb-2 font-serif font-semibold">
                  We are Jalanta Stays Visionaries
                </h3>
                <div className="mt-3 text-base font-semibold text-gray-700 flex flex-col gap-8 opacity-90 leading-7">
                  <p className="mt-10">
                    We have been working since 11 years in the Stream of
                    providing Hospitality towards Villa makers and open their
                    warm homes to visionaries.
                  </p>
                  <p>
                    We are the coordinators for the Owner and Public ensuring a
                    smooth experience thought out their stay at the luxurious
                    villas.
                  </p>
                  <p>
                    We are a team equipped from Ground Level to the Hospitality
                    of our Guest to Provide an ecstatic stay.
                  </p>
                  <p>
                    At Jalanta Stays we’re passionate about making a lifestyle
                    reality for as long as you like.
                  </p>
                  <p>
                    Please feel free to provide us any feedback and we look
                    forward for the communities support through the process.
                  </p>
                </div>
                <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-2 xl:gap-6 mt-8">
                  <div className="p-8 bg-indigo-50 shadow-sm rounded-lg">
                    <span className="text-3xl block font-extrabold font-serif mb-4 text-gray-800">
                      4K
                    </span>
                    <h4 className="text-lg font-serif font-bold mb-1">
                      Listed Villas
                    </h4>
                    <p className="mb-0 opacity-90 leading-7">
                      Our identity is Our best quality and Unprocessed herbs
                      which can be verified everywhere.
                    </p>
                  </div>
                  <div className="p-8 bg-indigo-50 shadow-sm rounded-lg">
                    <span className="text-3xl block font-extrabold font-serif mb-4 text-gray-800">
                      2K
                    </span>
                    <h4 className="text-lg font-serif font-bold mb-1">
                      Lovely Customer
                    </h4>
                    <p className="mb-0 opacity-90 leading-7">
                      Competently productize virtual models without performance.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-10 lg:mt-0">
                <NavLink to="/">
                  <img
                    width={920}
                    height={820}
                    src={logo_path}
                    alt="logo"
                    className="rounded-xl"
                  />
                </NavLink>
              </div>

            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AboutUs;
