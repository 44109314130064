import { Button } from "antd";
import { FormActionArea } from "containers/SinglePage/Reservation/Reservation.style";
import React from "react";
import { BsCreditCard2Back, BsFillDiscFill } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { GoAlert } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const Fail = () => {
const Navigate = useNavigate();

    return (
        <div className="flex justify-center m-20 " >
            <div
                class="block rounded-lg text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                    class=" flex justify-center border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
                    <FiAlertTriangle opacity={0.6} size={150} color="rgba(255, 0, 0, 0.6)"/>
                    {/* <BsCreditCard2Back size={150}/> */}
                </div>
                <div class="p-6">
                    <h5
                        class="mb-2 text-xl font-medium leading-tight text-red-500 dark:text-neutral-50">
                        Your Payment is failed ...
                    </h5>
                    <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                        With supporting text below as a natural lead-in to additional
                        content.
                    </p>
                    <FormActionArea>
                        <Button
                            type="primary"
                            onClick={() => Navigate("/")}
                        >
                            Back To Home
                        </Button>
                    </FormActionArea>
                </div>

            </div>
        </div>
    );
};

export default Fail;
