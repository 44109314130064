import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";
import { logo_path } from "action/api";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Footers
      logo={
        <div className="flex  items-center">
          <NavLink to="/">
          <img
             linkTo="/"
            src={logo_path}
            alt="Jalanta Stays Logo"
            className="w-20"
          />
          </NavLink>
          {/* <p className="text-js font-semibold text-xl">Jalanta</p> */}
        </div>
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} Jalanta Stays.`}
    />
  );
};

export default Footer;
