import React, { useState, useEffect } from "react";
import PageHeader from "./PageHeader";
import { image_url, logo_path } from "action/api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import { FieldWrapper, SwitchWrapper, Label } from '../Auth.style';
import { useForm, Controller } from 'react-hook-form';
// import Label from "./Label";
import { Input, Switch, Button, Checkbox, Spin } from 'antd';
import { SwitchWrapper, Label } from "containers/Auth/Auth.style";
import { FormActionArea } from "containers/SinglePage/Reservation/Reservation.style";
import { left } from "styled-system";
// import EasebuzzCheckout from 'easebuzz-checkout';
import axios from 'axios';

const CheckOutPage = () => {
    const navigate = useNavigate();
    const {
        control,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
    });
    // const EasebuzzCheckout = "";
    const [searchParam] = useSearchParams();
    const [terms, setTerms] = useState("")
    const [msg, setMsg] = useState("")
    // console.log("vistorData: ", searchParam.get("VisitorData"));
    // console.log("base_image: ", searchParam.get("base_image"));

    const [data, setData] = useState(JSON.parse(searchParam.get("VisitorData")))
    const [coupon, setCoupon] = useState();
    // const [name, setName] = useState(data.nae)

    const { booking_name,
        mobile_no,
        email_id,
        start_date,
        end_date,
        total_payment,
        no_of_guest,
        adons_id,
        villa_id,
        name,
        location_name,
        guest_limit,
        advance_amt,
        total_amt,
        day,
        add_guest_charge,
        extraGuest } = data

    console.log("total_amt :", total_amt);
    console.log("advance_amt :", advance_amt);

    const [totalAdd, setTotalAdd] = useState(add_guest_charge * extraGuest)
    const [subToatl, setSubTotal] = useState(parseInt(total_amt) + parseInt(totalAdd));
    const eighteenPercent = subToatl * 0.18;
    const getGst = total_amt + eighteenPercent;
    console.log("getGst :", getGst);

    const [bookingAmt, setBookingAmt] = useState(getGst - (getGst * (advance_amt / 100)))

    console.log("bookingAmt :", bookingAmt);


    React.useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        const script = document.createElement('script');
        script.src =
            'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [loading, setLoading] = useState(false);

    const paymentSucces = async (data) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name_on_card": data.name_on_card,
            "bank_ref_num": data.bank_ref_num,
            "udf3": data.udf3,
            "hash": data.hash,
            "firstname": data.firstname,
            "net_amount_debit": data.net_amount_debit,
            "payment_source": data.payment_source,
            "surl": data.surl,
            "error_Message": data.error_Message,
            "issuing_bank": data.issuing_bank,
            "cardCategory": data.cardCategory,
            "phone": data.phone,
            "easepayid": data.easepayid,
            "cardnum": data.cardnum,
            "key": data.key,
            "udf8": data.udf8,
            "unmappedstatus": data.unmappedstatus,
            "PG_TYPE": data.PG_TYPE,
            "addedon": data.addedon,
            "cash_back_percentage": data.cash_back_percentage,
            "status": data.status,
            "udf1": data.udf1,
            "merchant_logo": data.merchant_logo,
            "udf6": data.udf6,
            "udf10": data.udf10,
            "txnid": data.txnid,
            "productinfo": data.productinfo,
            "furl": data.furl,
            "card_type": data.card_type,
            "amount": data.amount,
            "udf2": data.udf2,
            "udf5": data.udf5,
            "mode": data.mode,
            "udf7": data.udf7,
            "error": data.error,
            "udf9": data.udf9,
            "bankcode": data.bankcode,
            "deduction_percentage": data.deduction_percentage,
            "email": data.email,
            "udf4": data.udf4
        });

        var requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/paymentSuccess_key", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log('payment success', result);
                if (result.Status === 200) {
                    setLoading(false)
                    navigate(`/booking-successfull/${result.id}`)
                }
            })
            .catch(error => console.log('error', error));

    }

    const paymentFailed = async (data) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name_on_card": data.name_on_card,
            "bank_ref_num": data.bank_ref_num,
            "udf3": data.udf3,
            "hash": data.hash,
            "firstname": data.firstname,
            "net_amount_debit": data.net_amount_debit,
            "payment_source": data.payment_source,
            "surl": data.surl,
            "error_Message": data.error_Message,
            "issuing_bank": data.issuing_bank,
            "cardCategory": data.cardCategory,
            "phone": data.phone,
            "easepayid": data.easepayid,
            "cardnum": data.cardnum,
            "key": data.key,
            "udf8": data.udf8,
            "unmappedstatus": data.unmappedstatus,
            "PG_TYPE": data.PG_TYPE,
            "addedon": data.addedon,
            "cash_back_percentage": data.cash_back_percentage,
            "status": data.status,
            "udf1": data.udf1,
            "merchant_logo": data.merchant_logo,
            "udf6": data.udf6,
            "udf10": data.udf10,
            "txnid": data.txnid,
            "productinfo": data.productinfo,
            "furl": data.furl,
            "card_type": data.card_type,
            "amount": data.amount,
            "udf2": data.udf2,
            "udf5": data.udf5,
            "mode": data.mode,
            "udf7": data.udf7,
            "error": data.error,
            "udf9": data.udf9,
            "bankcode": data.bankcode,
            "deduction_percentage": data.deduction_percentage,
            "email": data.email,
            "udf4": data.udf4
        });

        var requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/paymentFailed_key", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log('payment success', result);
                if (result.Status === 200) {
                    setLoading(false)
                    navigate(`/booking-failed`)
                }
            })
            .catch(error => console.log('error', error));
        setLoading(false);
    }

    const getToken = async () => {

        const script = document.createElement('script');

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "amount": parseFloat(subToatl + eighteenPercent - bookingAmt).toFixed(2),
            "firstname": data.booking_name,
            "email": data.email_id,
            "phone": data.mobile_no,
            "productinfo": data.name,
            "udf1": data.villa_id,
            "udf2": data.start_date,
            "udf3": data.end_date,
            "udf4": bookingAmt, // #pending amount
            "udf5": subToatl + eighteenPercent, //subToatl , #total amount
            "udf6": data.no_of_guest
        });

        var requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/generate_payment_key", requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status == 1) {
                    console.log("generate payment", result);
                    script.src =
                        'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js';
                    script.async = true;
                    script.onload = () => {
                        const easebuzzCheckout = new window.EasebuzzCheckout("XAB11S113P", 'prod');
                        const options = {
                            access_key: result.access_key, // access key received via Initiate Payment
                            onResponse: (response) => {
                                console.log("Data", response);
                                if (response.status == "success") {
                                    paymentSucces(response);
                                } else {
                                    paymentFailed(response);
                                }
                            },
                            theme: '#476288', // color hex
                        };
                        easebuzzCheckout.initiatePayment(options);
                    };
                    document.body.appendChild(script);
                    //     console.log("Its end 122");
                } else {
                    alert('Payment Cancel');
                }
            })

            .catch(error => console.log('error', error));

        // const script = document.createElement('script');

    }

    // const easebuzzCheckout = {
    //     apiKey: "2PBP7IABZ2",
    //     mode: "test",
    //     initiatePayment: function (options) {
    //         // Your initiatePayment logic here

    //         // Simulate the initiatePayment logic (replace this with actual implementation)
    //         console.log("Simulating initiatePayment:", options);
    //         const response = {
    //             status: "success", // You can customize this for testing purposes
    //             // Add other response data as needed
    //         };
    //         options.onResponse(response);
    //     },
    //     // Add other methods and properties as needed
    // };

    // const getToken = () => {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "amount": parseFloat(subToatl - bookingAmt).toFixed(2),
    //         "firstname": data.booking_name,
    //         "email": data.email_id,
    //         "phone": data.mobile_no,
    //         "productinfo": data.name,
    //         "udf1": data.villa_id,
    //         "udf2": data.start_date,
    //         "udf3": data.end_date,
    //         "udf4": data.bookingAmt,
    //         "udf5": data.subToatl,
    //         "udf6": data.no_of_guest
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch("https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/generate_payment_key", requestOptions)
    //         .then(response => response.json())
    //         .then((result) => {
    //             if (result.status === 1) {
    //                 loadEasebuzzScriptAndInitiatePayment(result);
    //             } else {
    //                 alert('Payment Cancel');
    //             }
    //         })
    //         .catch(error => console.log('error', error));
    // };

    // function loadEasebuzzScriptAndInitiatePayment(result) {
    //     const script = document.createElement('script');
    //     script.src = 'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js';
    //     script.async = true;

    //     script.onload = () => {
    //         initiatePaymentWithEasebuzz(result);
    //     };

    //     document.body.appendChild(script);
    // }

    // function initiatePaymentWithEasebuzz(result) {
    //     const options = {
    //         access_key: result.access_key,
    //         onResponse: (response) => {
    //             console.log("HASAN", response);
    //             if (response.status === "success") {
    //                 paymentSucces(response);
    //             } else {
    //                 alert('Payment Failed');
    //             }
    //         },
    //         theme: '#123456',
    //     };

    //     easebuzzCheckout.initiatePayment(options);
    // }

    return (
        <>
            {loading ?
                <Spin /> :
                <form >
                    <div>
                        <>
                            <div className="grid justify-center mt-10">
                                <div className="border p-5 lg:px-8 lg:py-8 rounded-lg bg-white order-1 sm:order-2">
                                    <h2 className="font-semibold font-serif text-lg pb-4 ml-5">
                                        Booking Summary
                                    </h2>
                                    <div className="overflow-y-hidden flex-grow scrollbar-hide w-full max-h-64  block">
                                        {/* {spinner && (
                                <div className="max-h-64 flex justify-center">
                                    <ScaleLoader color="#36d7b7" className="mt-20 mb-20" />
                                </div>
                            )} */}
                                        <input type="hidden" name="amount" value={parseFloat(subToatl - bookingAmt).toFixed(2)}></input>
                                        <input type="hidden" name="firstname" value={data.booking_name}></input>
                                        <input type="hidden" name="email" value={data.email_id}></input>
                                        <input type="hidden" name="phone" value={data.mobile_no}></input>
                                        <input type="hidden" name="productinfo" value={data.name}></input>
                                        <input type="hidden" name="udf1" value={data.villa_id}></input>
                                        <input type="hidden" name="udf2" value={data.start_date}></input>
                                        <input type="hidden" name="udf3" value={data.end_date}></input>
                                        <input type="hidden" name="udf4" value={bookingAmt}></input>
                                        <input type="hidden" name="udf5" value={subToatl}></input>
                                        <input type="hidden" name="udf6" value={no_of_guest}></input>
                                        <div
                                            className="grid grid-cols-2 align-middle justify-center"
                                        >
                                            <div className=" m-5">
                                                <img
                                                    className="border rounded-lg"
                                                    key=""
                                                    src={image_url + searchParam.get("base_image")}
                                                    width={200}
                                                    height={200}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="" >

                                                <p
                                                    // onClick={closeCartDrawer}
                                                    className="truncate text-xl font-bold text-heading line-clamp-1 mt-10"
                                                >
                                                    {/* {el.products.product_name} */}
                                                    {data.name}
                                                </p>
                                                <p
                                                    // onClick={closeCartDrawer}
                                                    className="truncate text-sm font-medium text-gray-700 text-heading line-clamp-1"
                                                >
                                                    {/* {el.products.product_name} */}
                                                    {data.location_name}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex mt-4 items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                        {`₹${Math.round(data.total_amt / data.day)} x ${data.day} Night`}
                                        <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                            {"₹" + data.total_amt}
                                        </span>
                                    </div>
                                    {extraGuest > 0 &&

                                        <div className="flex  items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                            {`₹${data.add_guest_charge}  x ${extraGuest} Additional Members`}
                                            <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                                {`₹${totalAdd}`}
                                            </span>
                                        </div>
                                    }

                                    <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                        18% GST of Total
                                        <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                            {`₹ ${eighteenPercent} `}
                                        </span>
                                    </div>

                                    <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                        Sub Total
                                        <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                            {`₹ ${subToatl + eighteenPercent}`} {/* here i was use subToatl before this eighteenPercent */}
                                        </span>
                                    </div>

                                    {/* <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                            Discount
                            <span className="ml-auto flex-shrink-0 font-bold text-red-500">
                                {/* {couponDetails ? (
                                    "- ₹" + couponDetails.coupon_value
                                ) : (
                                    <span>Not Applied</span>
                                )} 
                            </span>
                        </div> */}
                                    <div className="border-t mt-4">
                                        <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                            Booking Amount ({data.advance_amt}%)
                                            <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                                {`₹${(subToatl + eighteenPercent - bookingAmt).toFixed(2)}`}
                                            </span>
                                        </div>
                                        {/* <div className="flex items-center font-bold font-sans justify-between pt-5 text-sm uppercase text-emerald-600">
                                    Total cost
                                    <span className="font-sans font-bold text-lg">
                                        ₹ {parseFloat(data.total_amt) + (data.add_guest_charge * parseFloat(data.extraGuest) * parseFloat(data.day))}
                                        {/* {couponDetails
                                        ? subTotal + shippingCost - couponDetails.coupon_value
                                        : subTotal + shippingCost} 
                                        /-
                                    </span>
                                </div> */}
                                    </div>
                                    <div className="border-t mt-4">
                                        <div className="flex items-center py-2 text-sm w-full font-semibold text-gray-500 last:border-b-0 last:text-base last:pb-0">
                                            {advance_amt}% of Total
                                            <span className="ml-auto flex-shrink-0 text-gray-500 font-bold">
                                                {`₹${(subToatl + eighteenPercent - bookingAmt).toFixed(2)}`}
                                            </span>
                                        </div>

                                        <div className="flex items-center font-bold font-sans pt-5 text-sm uppercase text-emerald-600">
                                            Remaining &nbsp;<span className="text-red-400 m-0"> ₹ {(bookingAmt).toFixed(2)}</span>&nbsp; to be paid at check-in
                                        </div>
                                    </div>
                                    <br />
                                    <div>
                                        <b>Disclaimer:</b>
                                        <br />

                                        <ol style={{ color: 'red', listStyleType: 'circle', }}>
                                            <b>&bull; Please refrain from pressing the back button or </b>
                                            <br />
                                            <b style={{ marginLeft: '9px' }}>reloading the page while making the payment.</b> <br />
                                            <b>&bull; Kindly wait for the confirmation page to appear before proceeding.</b>
                                        </ol>

                                    </div>

                                    <div className=" mt-10">
                                        <SwitchWrapper>
                                            <Controller
                                                control={control}
                                                name="termsAndConditions"
                                                valueName="checked"
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox onChange={(e) => {
                                                        setTerms(e.target.checked)
                                                        setMsg("")
                                                    }} checked={terms} />
                                                )}
                                            />
                                            <p>&nbsp;I agree with <a class="text-blue-500 underline" href="https://jalantastays.com/privacy-policy" target="_blank">terms and conditions</a></p>

                                        </SwitchWrapper>
                                        {msg && <div className="text-red-500">{msg}</div>}
                                        <div className="mt-8 flex justify-center ">
                                            <FormActionArea>
                                                {terms ?
                                                    // <button class="bg-transparent hover:bg-teal-600 text-white-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                                    //     Book Villa
                                                    // </button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => getToken()}
                                                    >
                                                        Book Villa
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setMsg("Please accept terms and conditions")
                                                        }}
                                                    >
                                                        Book Villa
                                                    </Button>
                                                }
                                            </FormActionArea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </form>
            }
        </>
    );
};

export default CheckOutPage;


