import Heading from "components/UI/Heading/Heading";
import TextLink from "components/UI/TextLink/TextLink";
import NotFoundWrapper, { ContentWrapper } from "containers/404/404.style";
import React from "react";
import { GiVillage } from "react-icons/gi";

const NoVillaFound = () => {
  return (
    <div>
      <NotFoundWrapper>
        <ContentWrapper>
          <div className="flex justify-center">
            <GiVillage className="text-9xl" color="#12406d" />
          </div>
          <Heading as="h2" content="No Villa Found For This Location." />
          <TextLink link="/" content="Go Back" />
        </ContentWrapper>
      </NotFoundWrapper>
    </div>
  );
};

export default NoVillaFound;
