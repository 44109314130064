import SectionGrid from "components/SectionGrid/SectionGrid";
import SectionTitle from "components/SectionTitle/SectionTitle";
import Container from "components/UI/Container/Container";
import { PostPlaceholder } from "components/UI/ContentLoader/ContentLoader";
import Heading from "components/UI/Heading/Heading";
import useLocationApi from "library/hooks/useLoactionApi";
import useWindowSize from "library/hooks/useWindowSize";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { SINGLE_POST_PAGE } from "settings/constant";
import NoVillaFound from "./NoVillaFound";
import SinglePageWrapper, {
  PostImage,
} from "containers/SinglePage/SinglePageView.style";
import Carousel from "react-multi-carousel";
import ProductCard from "components/Card/ProductCard";
import { getLocatiionDetails, image_url } from "action/api";
import { Helmet } from "react-helmet";
import axios from 'axios';

const LocationInGrid = () => {
  // const [searchParam] = useSearchParams();
  const { id } = useParams();
  const { loading } = useLocationApi("/data/top-hotel.json");
  const { width } = useWindowSize();

  let posts = data;
  let limit;

  if (data && width <= 767) {
    posts = data.slice(0, 6);
    limit = 6;
  }
  if (data && width >= 768) {
    posts = data.slice(0, 10);
    limit = 10;
  }
  if (data && width >= 992) {
    posts = data.slice(0, 14);
    limit = 14;
  }
  if (data && width >= 1200) {
    posts = data.slice(0, 20);
    limit = 20;
  }
  const [data, setData] = useState([]);
  const [load, setload] = useState(true);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  const getLocationVillas = async () => {
    try {
      const response = await axios.get(`https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/getVillaByLocation/${id}`);
      if (response.data.status === 200) {
        setData(response.data.data);
      }
      setload(false);
    } catch (error) {
      console.error('Error fetching villa data by location:', error);
      setload(false);
    }
  };

const getLocationBYid = async()=>{
  setload(true);
  let action =await getLocatiionDetails(id)
  // console.log("location",action);
  if(action.status === 200){
    setName(action.data.location_name)
    setImage(action.data.image)
  }
  setload(false)
}

  useEffect(() => {
    window.scrollTo(0, 0);
    getLocationVillas();
    getLocationBYid()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    getLocationVillas();
    getLocationBYid()
  }, [id]);

  return (
    <>
     <Helmet>
          <title>Jalanta Stays - Location {name}</title>
        </Helmet>

    <SinglePageWrapper>
      <PostImage>
        <img
          className="absolute"
          src={
            image_url +
            image
          }
          alt="Listing details page banner"
        />
      </PostImage>
      <div className="bg-white border-b">
        <div className="ml-5">
          <SectionTitle
            title={<Heading content={name} />}
          />
        </div>
      </div>
      <Container fluid={true} className="mt-10">
        <ProductCard link={SINGLE_POST_PAGE} data={data} />
      </Container>
    </SinglePageWrapper>
    </>
  );
};

export default LocationInGrid;
