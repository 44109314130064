import { useState, useReducer, useEffect } from "react";

async function SuperFetch(
  url,
  method = "GET",
  headers = {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
  body = {}
) {
  let options = {
    method,
    headers,
  };
  if (method === "POST" || method === "PUT") options = { ...options, body };

  // authentication
  // we will had custom headers here.

  return fetch(url, options)
    .then((res) => {
      return Promise.resolve(res.json());
    })
    .catch((error) => Promise.reject(error));
}

function dataFetchReducer(state, action) {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: action.payload,
        total: action.length,
        loading: false,
        error: false,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        error: true,
      };
    case "LOAD_MORE":
      return {
        ...state,
        data: [
          ...state.data,
          ...state.total.slice(
            state.data.length,
            state.data.length + state.limit
          ),
        ],
        loading: false,
        error: false,
      };
    default:
      throw new Error();
  }
}

const useBHKapi = (initialUrl, limit = 10, initialData = []) => {
  const [url, setUrl] = useState(initialUrl);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: initialData,
    total: initialData,
    limit: limit,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });

      try {
        const result = await SuperFetch(url);
        if (!didCancel) {
          dispatch({
            type: "FETCH_SUCCESS",
            payload: result.data,
            length: result.data.length,
          });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: "FETCH_FAILURE" });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [url]);
  const loadMoreData = () => {
    dispatch({ type: "LOAD_MORE" });
  };
  const doFetch = (url) => {
    setUrl(url);
  };

  return { ...state, doFetch, loadMoreData };
};

export default useBHKapi;
