import Heading from "components/UI/Heading/Heading";
import TextLink from "components/UI/TextLink/TextLink";
import NotFoundWrapper, { ContentWrapper } from "containers/404/404.style";
import React from "react";
import { FcMoneyTransfer } from "react-icons/fc";

const NoBHKFound = () => {
  return (
    <div>
      <NotFoundWrapper>
        <ContentWrapper>
          <div className="flex justify-center">
            <FcMoneyTransfer className="text-9xl" color="#12406d" />
          </div>
          <Heading as="h2" content="We're Sorry.." />
          <p>We Can't find any villa according to your budget...</p>
          <TextLink link="/" content="Go Back" />
        </ContentWrapper>
      </NotFoundWrapper>
    </div>
  );
};

export default NoBHKFound;
