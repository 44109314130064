import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { FaMapMarkerAlt } from "react-icons/fa";
import { createSearchParams, useNavigate } from "react-router-dom";
import { LOCATION_DETAILS_PAGE } from "settings/constant";
import axios from "axios";

const LocationDropdown = ({sidebarHandler}) => {
  const [data, setData] = useState([]);
  const Navigate = useNavigate()

   const getAllLocation = async () => {
    try {
      const response = await axios.get('https://jalantastays.com/rest/api/X8K9Pz0/backend/public/api/location');
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching all locations:', error);
    }
  };

  useEffect(() => {
    getAllLocation();
  }, []);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const locationItems = data.map((location) =>
    getItem(location.location_name, location.id, null, null, "location")
  );

  const items = [
    getItem(<p className="font-bold">Location</p>, "sub1", <FaMapMarkerAlt />, [
      ...locationItems,
    ]),
  ];
  const onClick = (e) => {
    // console.log(e);
    Navigate({
      pathname: LOCATION_DETAILS_PAGE,
      search: createSearchParams({
        id: e.key,
        // name: e.location_name,
        // image: e.image,
      }).toString(),
    })
    sidebarHandler()
  };

  return (
    <div>
      <Menu
        onClick={onClick}
        // defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default LocationDropdown;
