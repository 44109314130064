import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { FaRupeeSign } from "react-icons/fa";
import { getBudget } from "action/api";
import { BUDGET_DETAILS_PAGE } from "settings/constant";
import { createSearchParams, useNavigate } from "react-router-dom";

const BudgetDropDown = ({ sidebarHandler }) => {
  const [data, setData] = useState([]);
  const Navigate = useNavigate()
  const getAllBudget = async () => {
    let result = await getBudget()
    setData(result.data);

  };

  useEffect(() => {
    getAllBudget();
  }, []);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const bhkItems = data.map((bhk) =>
    getItem(bhk.price, bhk.id, null, null, "bhk")
  );

  const items = [
    getItem(<p className="font-bold">Budget's</p>, "sub1", <FaRupeeSign />, [
      ...bhkItems,
    ]),
  ];
  const onClick = (e) => {
    Navigate({
      pathname: BUDGET_DETAILS_PAGE,
      search: createSearchParams({
        id: e.key,
        // name: el.price,
        // budgetImage: el.image,
      }).toString(),
    })
    sidebarHandler()
  };

  return (
    <div>
      <Menu
        onClick={onClick}
        // defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default BudgetDropDown;
