import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { CONTACT_PAGE, ABOUT_PAGE, HOME_PAGE, LOCATION_DETAILS_PAGE, BUDGET_DETAILS_PAGE, BHK_DETAILS_PAGE } from "settings/constant";
import { FcAbout, FcBusinessContact, FcCallback } from "react-icons/fc";
import { FaInfo, FaInfoCircle } from "react-icons/fa";

const MobileMenu = ({ className, sidebarHandler }) => {
  const navigations = [
    {
      label: (
        <NavLink
          to={`${CONTACT_PAGE}`}
          onClick={sidebarHandler}
          className="font-bold hover:text-[#12406d] flex gap-4"
        >
          <FcBusinessContact />
          <p>ContactUs</p>
        </NavLink>
      ),
      key: "conatactus",
    },
    {
      label: (
        <div
          // to={`${CONTACT_PAGE}`}
          onClick={sidebarHandler}
          className="font-bold hover:text-[#12406d] mt-2 flex gap-4"
        >
          <FcCallback />
         <p><a href="tel:(+91) 9049933387">+91 9049933387</a></p> 
          {/* <p>+91 9049933387</p> */}
        </div>
      ),
      key: "Call",
    },
    {
      label: (
        <NavLink
          onClick={sidebarHandler}
          to={`${ABOUT_PAGE}`}
          className="font-bold hover:text-[#12406d] mt-2 flex gap-4"
        >
          <FaInfoCircle />
          <p>AboutUs</p>
        </NavLink>
      ),
      key: "aboutus",
    },
  ];

  return <Menu className={className} items={navigations} />;
};

export default MobileMenu;
